import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { projectConfig, projectEncryptData } from './app/project.config';
import { environment } from './environments/environment';

declare global {
  var selfEnv: string;
  var selfPc: string;
}
window['selfEnv'] = projectEncryptData(environment);
window['selfPc'] = projectEncryptData(projectConfig);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
