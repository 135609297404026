import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr } from 'ngx-toastr';
import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ProjectConfigService } from './services/project-config.service';
import {register} from 'swiper/element/bundle';
import { TokenInterceptorService } from './services/interceptor.service';
import { AuthService } from './services/auth.service';

register();

function initializeApp(projectConfigService: ProjectConfigService, authService: AuthService) {
  return async () => {
    await projectConfigService.loadConfig();
    await authService.getAuthPromise();
  };
}

export const mainAppConfigProviders: ApplicationConfig['providers'] = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
  provideZoneChangeDetection({ eventCoalescing: true }),
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [ProjectConfigService, AuthService],
    multi: true
  },
  provideHttpClient(
    withInterceptorsFromDi()
  ),
  provideAnimations(),
  provideToastr(),
  provideAnimationsAsync(),
];
